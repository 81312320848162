export const EVENTS_APP_DEF_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351'
export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9'

export const BASE_URL = 'https://www.wix.com'

export const MEMBERS_DEPENDANT_APPS: {[appname: string]: any} = {
  profile: {
    appDefinitionId: '14dbef06-cc42-5583-32a7-3abd44da4908',
    pageId: 'about',
  },
  events: {
    appDefinitionId: EVENTS_APP_DEF_ID,
    pageId: 'events_members_page',
  },
  notifications: {
    appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
    pageId: 'notifications_app',
  },
  settings: {
    appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
    pageId: 'settings',
  },
}

export const PROFILE_PAGE = {
  appDefinitionId: MEMBERS_DEPENDANT_APPS.profile.appDefinitionId,
  pageId: MEMBERS_DEPENDANT_APPS.profile.pageId,
  method: 'addApplication',
  loginMenuTitle: 'Profile',
  showInLoginMenu: true,
  menuOrder: 1,
  socialHome: true,
  urlOverride: 'profile',
}

export const EVENTS_PAGE = {
  appDefinitionId: MEMBERS_DEPENDANT_APPS.events.appDefinitionId,
  pageId: MEMBERS_DEPENDANT_APPS.events.pageId,
  method: 'addSection',
  menuOrder: 2,
  urlOverride: 'events',
}

export const NOTIFICATIONS_PAGE = {
  numbers: {
    key: 'notificationsCount',
    default: 0,
  },
  appDefinitionId: MEMBERS_DEPENDANT_APPS.notifications.appDefinitionId,
  pageId: MEMBERS_DEPENDANT_APPS.notifications.pageId,
  method: 'addApplication',
  menuOrder: 2,
  social: false,
  urlOverride: 'notifications',
}

export const SETTINGS_PANEL = {
  appDefinitionId: MEMBERS_DEPENDANT_APPS.settings.appDefinitionId,
  pageId: MEMBERS_DEPENDANT_APPS.settings.pageId,
  method: 'addApplication',
  menuOrder: 4,
  social: false,
  urlOverride: 'settings',
}

export type ProgressBarConfig = {
  title: string
  step1: string
  step2: string
  step3: string
  timeBetweenSteps: number,
}

export const BASE_INSTALL_CONFIG: ProgressBarConfig = {
  title: 'membersInstallProgressBarTitle',
  step1: 'membersInstallProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 4500,
}

export const PROMO_INSTALL_CONFIG: ProgressBarConfig = {
  title: 'membersPromoInstallProgressBarTitle',
  step1: 'membersInstallProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 4500,
}

export const PROMO_UPDATE_CONFIG: ProgressBarConfig = {
  title: 'membersPromoUpdateProgressBarTitle',
  step1: 'membersPromoUpdateProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 3000,
}
