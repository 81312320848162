import Experiments from '@wix/wix-experiments'
import {BASE_URL} from './constants'

export const fetchExperiments = async (): Promise<Experiments> => {
  const experiments = new Experiments({
    baseUrl: BASE_URL,
    scope: 'wix-events-ui',
  })

  await experiments.ready()

  return experiments
}
